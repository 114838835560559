import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Responsive, Grid, Segment, Container } from 'semantic-ui-react'
import { LoadingScreen, Block, HomepageImage, ImageContainer, LinkButton, MobileHompageWrapper, MobileHomepageRow, HomepageRow, ImageRightColumn, HomepageGrid, TabletSegment, DesktopSegment } from './HomepageBlack.styles'
import theme from '@styles/theme'
import { HomepageItemProps } from '@models/Homepage/Homepage.models'
import { Sources } from '@elements/index'
import { rules } from '@styles/mq'


interface Props {
  data: HomepageItemProps
}

interface States {
  width: number
}

class HomepageBlack extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {
      width: 0, // or your default width here
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  blackComponent = (isMobile = false, isTablet = false) => {
    const data = this.props.data;
    const height = (this.state.width-28)/1.17
    console.log('etain-width', this.state.width)
    console.log('etain-height', height)
    return (<>
      {isMobile ? 
       <Block img={isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url)} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>            
          <Grid stackable>
            <HomepageRow columns={2}>
              <Grid.Column mobile={16}>
                <div className="center">
                    {data.newTab?.length ? <div className="new">
                      {data.newTab}
                    </div> : null
                    }
                    <div className="nav-button">
                      <div className="btn1">
                        <a id={data.createPlateUniqueGTMID} href={data.new_plate_link}>
                          <Grid>
                            <Grid.Column width={14}>
                              <div className="title">{data.new_plate_title}</div>
                              <div className="description">{data.new_plate_description}</div>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                              </svg>
                            </Grid.Column>
                          </Grid>
                          </a>
                      </div>
                      <div className="btn1 btn2">
                        <a id={data.redesignPlateUniqueGTMID} href={data.redesign_plate_link}>
                          <Grid>
                            <Grid.Column width={14}>
                              <div className="title">{data.redesign_plate_title}</div>
                              <div className="description">{data.redesign_plate_description}</div>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                              </svg>
                            </Grid.Column>
                          </Grid>
                          </a>
                      </div>
                      <LinkButton hasText={data.giftingText ? true: false} isMobile={isMobile}>
                        <a id={data.giftingGTMID} href={data.giftingLink}>
                          {data.giftingText}
                        </a>
                      </LinkButton>
                    </div>
                </div>
              </Grid.Column>             
                <ImageContainer>
                  <HomepageImage height={(this.state.width-28)/1.17}
                      className="banner"
                      lowSrc={`${isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url) }?w=350&auto=format`}
                      sources={Sources(isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url), rules, theme.imageBreakpoints)}
                    />
                </ImageContainer>                
            </HomepageRow> 
          </Grid>
        </Block>:
      (isTablet ? 
      <TabletSegment>        
          <Block img={isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url)} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>            
            <HomepageGrid stackable>
              <HomepageRow columns={2}>
                <Grid.Column tablet={this.state.width > 1000  ? 5 : (this.state.width > 850 ? 6 : 7)}>
                  <div className="center">
                      {data.newTab?.length ? <div className="new">
                        {data.newTab}
                      </div> : null
                      }
                      <div className="nav-button">
                        <div className="btn1">
                          <a id={data.createPlateUniqueGTMID} href={data.new_plate_link}>
                            <Grid>
                              <Grid.Column width={14}>
                                <div className="title">{data.new_plate_title}</div>
                                <div className="description">{data.new_plate_description}</div>
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                              </Grid.Column>
                            </Grid>
                            </a>
                        </div>
                        <div className="btn1 btn2">
                          <a id={data.redesignPlateUniqueGTMID} href={data.redesign_plate_link}>
                            <Grid>
                              <Grid.Column width={14}>
                                <div className="title">{data.redesign_plate_title}</div>
                                <div className="description">{data.redesign_plate_description}</div>
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                              </Grid.Column>
                            </Grid>
                            </a>
                        </div>
                        <LinkButton hasText={data.giftingText ? true: false} isMobile={isMobile}>
                          <a id={data.giftingGTMID} href={data.giftingLink}>
                            {data.giftingText}
                          </a>
                        </LinkButton>
                      </div>
                  </div>
                </Grid.Column>
                <Grid.Column tablet={this.state.width > 1000  ? 11 : (this.state.width > 850 ? 10 : 9)}>
                  <ImageContainer hasPadding={this.state.width > 1000 ? true : false}>
                    <HomepageImage
                        className="banner"
                        lowSrc={`${isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url) }?w=350&auto=format`}
                        sources={Sources(isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url), rules, theme.imageBreakpoints)}
                      />
                  </ImageContainer>  
                </Grid.Column>
              </HomepageRow> 
            </HomepageGrid>
          </Block>        
      </TabletSegment> : 
      <DesktopSegment>
        <Container>
          <Block img={isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url)} isMobile={isMobile} mobileTextAlignment={data.mobileTextAlignment}>            
            <Grid stackable>
              <HomepageRow columns={2}>
                <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={4} widescreen={4}>
                  <div className="center">
                      {data.newTab?.length ? <div className="new">
                        {data.newTab}
                      </div> : null
                      }
                      <div className="nav-button">
                        <div className="btn1">
                          <a id={data.createPlateUniqueGTMID} href={data.new_plate_link}>
                            <Grid>
                              <Grid.Column width={14}>
                                <div className="title">{data.new_plate_title}</div>
                                <div className="description">{data.new_plate_description}</div>
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                              </Grid.Column>
                            </Grid>
                            </a>
                        </div>
                        <div className="btn1 btn2">
                          <a id={data.redesignPlateUniqueGTMID} href={data.redesign_plate_link}>
                            <Grid>
                              <Grid.Column width={14}>
                                <div className="title">{data.redesign_plate_title}</div>
                                <div className="description">{data.redesign_plate_description}</div>
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                              </Grid.Column>
                            </Grid>
                            </a>
                        </div>
                        <LinkButton hasText={data.giftingText ? true: false} isMobile={isMobile}>
                          <a id={data.giftingGTMID} href={data.giftingLink}>
                            {data.giftingText}
                          </a>
                        </LinkButton>
                      </div>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={12} widescreen={12}>
                  <ImageContainer hasPadding={this.state.width >= 1440 ? true : false}>
                    <HomepageImage
                        className="banner"
                        lowSrc={`${isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url) }?w=350&auto=format`}
                        sources={Sources(isMobile ? data.mobile_image_url : (isTablet ? data.tablet_image_url : data.desktop_image_url), rules, theme.imageBreakpoints)}
                      />
                  </ImageContainer>  
                </Grid.Column>
              </HomepageRow> 
            </Grid>
          </Block>
        </Container>
      </DesktopSegment>)}      
    </>)
  }

  render() {
    const { width } = this.state;
    return (
      width === 0 ? <LoadingScreen></LoadingScreen> :
        <LoadingScreen>
          <Responsive getWidth={() => { return width; }} maxWidth={theme.responsive.mobile.maxWidth}>
            {this.blackComponent(true, false)}
          </Responsive>
          <Responsive getWidth={() => { return width; }} minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
            {this.blackComponent(false, true)}
          </Responsive>
          <Responsive getWidth={() => { return width; }} minWidth={theme.responsive.desktop.minWidth}>
            {this.blackComponent()}
          </Responsive>
        </LoadingScreen>
    );
  }
}

export default HomepageBlack
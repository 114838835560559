import React, { Fragment } from 'react'

import {
    MobileImageContainer,
    InfoBannerPlateImage,
    MobileDetailContainer,
    Header,
    Price,
    FontColor,
    PriceDetails,
    DescriptionGrid,
    HeaderRow,
    LeftColumn,
    RightColumn,
    BottomRow,
    RightHeaderRow,
    RightBottomRow
} from './PlateDesignStyleContainer.styles'
import { calculateDefaultPrice } from '@components/PlateDesignsPreview/designPreviewHelper';
import { TransactionTypesSetting } from '@models/ProductModels';


interface MobilePlateImageModel {
    data: any
    transactionType: any
}

export const PlateDesignStyleContainer = ({ data }: MobilePlateImageModel) => {
    const newPrice = calculateDefaultPrice(data.metaInfo, TransactionTypesSetting.New)
    const redesignPrice = calculateDefaultPrice(data.metaInfo, TransactionTypesSetting.Redesign)
    var fontColors = data.products.categories[0].products[0].variants.length

    return (
        <MobileImageContainer>
            <InfoBannerPlateImage src={data.metaInfo.featureImage}/>
            <MobileDetailContainer >
                <DescriptionGrid columns={2}>
                    <LeftColumn width={9}>
                        <HeaderRow>
                            <Header>{data.metaInfo.name}</Header>  
                        </HeaderRow>
                        <BottomRow>
                            <Price>
                                {fontColors > 1 ?
                                <FontColor>+{fontColors || 0} font colours</FontColor>
                                : <FontColor>1 font colour</FontColor>
                                }                        
                            </Price>
                        </BottomRow>
                    </LeftColumn>
                    <RightColumn width={7}>
                        <RightHeaderRow>
                            <Price>
                                <PriceDetails>New<span>${newPrice}</span></PriceDetails>
                            </Price>
                        </RightHeaderRow>
                        <RightBottomRow>                    
                            <Price>
                                <PriceDetails>Redesign<span>${redesignPrice}</span></PriceDetails>
                            </Price>
                        </RightBottomRow>
                    </RightColumn>
                </DescriptionGrid>                
            </MobileDetailContainer>
        </MobileImageContainer>
    )
}
export default PlateDesignStyleContainer